import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

const allSignaturesQuery = graphql`
query allSignatures {
  dataJson {
    signatures {
      type
      slug
      name
    }
  }
}
`

const Title = {
  textAlign: 'center',
  fontFamily: 'Arial',
  textTransform: 'uppercase'
}
const SignatureWrapper = {
  display: 'flex',
  fontFamily: 'Arial',
  justifyContent: 'center'
}
const LinkStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '10vw',
  width: '10vw',
  backgroundColor: 'rgba(0,0,0,0.1)',
  margin: '0 10px',
  padding: '20px',
  textDecoration: 'none',
  textAlign: 'center',
  color: 'red',
  fontWeight: 'bold',
  borderBottom: 'solid 2px red'
}

const chooseColor = (type) => {
  if (type === 'classic') {
    return '#495057'
  } else {
    return '#495057'
  }
}

const IndexPage = () => (
  <StaticQuery
  query={allSignaturesQuery}
  render={data => (
    <>
      <h1 style={Title}>Dehn</h1>
      <div style={SignatureWrapper}>
        {data.dataJson.signatures.map(((signature, index) => (
          <div key={index}>
            <Link
              style={Object.assign({}, LinkStyle, {
                borderBottomColor: chooseColor(signature.type),
                color: chooseColor(signature.type)
              })}
              to={`./signatures/${signature.slug}`}>
              {signature.name}
            </Link>
          </div>
        )))}
      </div>
    </>
  )} />
)

export default IndexPage
